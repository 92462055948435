import React from 'react';
import support from '../assets/logo-4.png';
import './Homepage.css';



function Homepage() {
    return (
      <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a href='https://app.getsurveyplus.com'><img className="images navbar-brand" src={support} alt="support"/></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse flex-row-reverse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link fw-bolder text-success" aria-current="page" href="https://getsurveyplus.com">Learn More</a>
              </li>
              <li className="nav-item fw-bolder">
                <a className="nav-link" href="https://app.getsurveyplus.com">Home</a>
              </li>
              <li className="nav-item fw-bolder">
                <a className="nav-link" href="https://play.google.com/store/apps/details?id=bfdapp.suveyplus">Playstore</a>
              </li>
              <li className="nav-item fw-bolder">
                <a className="nav-link" href="https://apps.apple.com/ng/app/surveyplus/id1537486349">Appstore</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      
      <div className="container-fluid">
      <div className="row">
        <div className="col-lg col-md-10 col-sm-9 sponsor-image text-center">
          <p className='fs-1 fw-bolder text-center sponsor-text1'>Hello !</p>
          <p className='fs-4 fw-bolder sponsor-text2'>What will you like to do?</p>
        <a type="button" href='https://sponsors.getsurveyplus.com' className=" btn btn-outline-success btn-lg">Conduct Survey</a>
  
        </div>
        <div className="col-lg col-md-10 col-sm-9 sponsor-image2 text-center">
          <p className='fs-1 fw-bolder text-center sponsor-text1'>Hello !</p>
          <p className='fs-4 fw-bolder sponsor-text2'>What will you like to do?</p>
          <a type="button" href='https://respondents.getsurveyplus.com' className=" btn btn-outline-success btn-lg fw-bold">Take Survey</a>
          </div>
      </div>
    </div>
    
    <div className='container-fluid text-center bg-light pt-2 pb-1'>
      <p className='copyright'>Copyright © 2022 SurveyPlus Limited</p>
    </div>

     </div>
    )
}

export default Homepage
