import React, {useState, useEffect} from 'react';
import Homepage from './components/Homepage';
import surveyplus from '../src/assets/Surveyplus.png';
import './App.css';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className='text-center'>
          <img className="loader" src={surveyplus} alt="surveyplus"/>
        </div>
        
      ) : (
        <Homepage/>
      )}
      
    </div>
  );
}

export default App;
